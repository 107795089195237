import React from 'react';
import Helmet from 'react-helmet';
import { Link, graphql } from 'gatsby';

import MarkdownWrapper from '../MarkdownWrapper';
import Layout from '../Layout';
import styles from './styles.module.css';
import TwitterCallout from '../TwitterCallout';

const Post = ({ data, location }) => {
  const post = data.markdownRemark;
  return (
    <Layout location={location}>
      <div className={styles.wrapper}>
        <Helmet>
          <title>{`Building LloydsDirect • ${post.frontmatter.title}`}</title>
          {post.frontmatter.canonical && (
            <link rel="canonical" href="{post.frontmatter.canonical}" />
        )}
        </Helmet>
        <Link to="/" className={styles.backButton} aria-hidden tabIndex="-1">
          <span className={styles.backArrow}>{'←'}</span>{' '}
          <span className={styles.backLabel}>Building LloydsDirect</span>
        </Link>
        <h1 className={styles.postTitle}>{post.frontmatter.title}</h1>
        {post.frontmatter.subtitle && (
          <h2 className={styles.postSubtitle}>{post.frontmatter.subtitle}</h2>
        )}
        <p className={styles.postMeta}>
          <time>
            {post.frontmatter.date}{' '}
            {post.frontmatter.author && ` by ${post.frontmatter.author}`}
          </time>
        </p>
        {post.frontmatter.image && !post.frontmatter.hidePostImage && (
          <div className={styles.postImageWrapper}>
            <img
              className={styles.postImage}
              src={post.frontmatter.image.childImageSharp.fixed.src}
              alt={post.frontmatter.title}
            />

            {post.frontmatter.imageAuthor && (
              <p className={styles.postImageMeta}>
                Photo by {post.frontmatter.imageAuthor}
              </p>
            )}
          </div>
        )}

        <div className={styles.postContent}>
          <MarkdownWrapper html={post.html} />
        </div>
      </div>

      <TwitterCallout />
    </Layout>
  );
};

export const query = graphql`
  query($path: String!) {
    markdownRemark(fields: { path: { eq: $path } }) {
      html
      frontmatter {
        canonical
        title
        subtitle
        author
        date(formatString: "D MMMM YYYY")
        image {
          childImageSharp {
            fixed(width: 1560) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        imageAuthor
        hidePostImage
      }
    }
  }
`;

export default Post;
